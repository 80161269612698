export default {
  blogerName: 'LAVANDA',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/makbonus'
    },
    {
      name: 'youtube',
      url: 'https://youtube.com/@GUFFI228?si=jXmH1tr3-Z_6MzJN'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/volhebnik_guffi?igsh=dnFrejR6Mmd2cTQ1&utm_source=qr'
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1go-irrs01.com/ceaac8616',
      gameTitle: 'The Dog House, Cleocatra, Gates of Olympus',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs01.com/cc1025e07',
      gameTitle: 'Savage Buffalo Spirit Megaways',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs01.com/c51ab57a9',
      gameTitle: 'Royal High-Road',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/ce266650d',
      gameTitle: ' All Lucky Clover 5',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/cee74d202',
      gameTitle: 'Dragon`s Gold 100',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs01.com/c5b06839f',
      gameTitle: 'Dragon`s Gold 100',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/c8436ddbf',
      gameTitle: 'Dragon`s Gold 100',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/c9afaf5ae',
      gameTitle: 'Dragon`s Gold 100',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/c06a8270d',
      gameTitle: 'Doors Of Sol',
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/c29763b5c',
      gameTitle: 'Doors Of Fresh',
    },
    {
      name: 'rox',
      url: 'https://fox-fwuocypyjf.com/c10fc2f97',
      gameTitle: 'Mechanical Clover',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>LAVANDA</strong> и получи 50 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'LAVANDA',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>100FS</strong><div>За регистрацию по промокоду</div>',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
